/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.NewsletterPrivacy {
  font-size: $fontSize-sm;
  line-height: 22px;
}

.NewsletterPrivacyNoBox {
  color: $greyDarkest;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 24px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .NewsletterPrivacyNoBox {
    padding: 0 8px;
  }
}
