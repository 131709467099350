/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.AlignCenter {
  text-align: center;
}

.CreatorQuestion {
  color: $grey;
  font-family: $fontFamily;
  font-size: $fontSize-xl;
  line-height: 26px;
  padding-bottom: 5px;
}

.FormDate {
  display: flex;

  > div {

    + div {
      margin-left: 24px;
    }
  }
}

.RadioWrapper {
  display: flex;
  margin-bottom: 24px;

  > div {

    + div {
      margin-left: 20px;
    }
  }
}

.PersonWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  > div {
    width: calc(50% - 10px);
  }
}

.CreatorQuestionDescription {
  color: $grey;
  font-family: $fontFamily;
  font-size: $fontSize-s;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 10px;
}

.CreatorAnswers {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 810px;
}

.CreatorAnswer {
  padding: 0 10px 12px;
  width: 33.33%;
}

.CreatorAnswerWrapper {
  position: relative;
  width: 100%;

  input {
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    visibility: visible;

    &:checked {

      + label.CreatorAnswerLabel {
        background: $purpleDarker;
        border: 1px solid $purpleDarker;
        color: $white;

        &:after {
          display: block;
        }
      }
    }
  }
}

.CreatorAnswerLabel {
  align-items: center;
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  box-sizing: border-box;
  color: $greyDarkest;
  cursor: pointer;
  display: flex;
  font-size: $fontSize-sm;
  height: 32px;
  justify-content: center;
  line-height: 22px;
  transition: all .2s $defaultBezier;
  width: 100%;

  &:after {
    color: $white;
    display: none;
    font-size: $fontSize-Icon12;
    position: absolute;
    right: 8px;
    top: 8px;
  }
}

.CreatorSummaryLabel {
  color: $grey;
  font-family: $fontFamily;
  font-size: $fontSize-xxl;
  line-height: 36px;
  margin-bottom: 32px;
}

.CreatorSummaryDescription {
  color: $grey;
  font-size: $fontSize-sm;
  line-height: 22px;
  margin: 0 auto;
  max-width: 600px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .PersonWrapper {
    display: block;

    > div {
      width: 100%;
    }
  }

  .FormDate {

    > div {
      flex: 1 1 auto;

      + div {
        margin-left: 8px;
      }
    }
  }

  .CreatorQuestion {
    font-size: $fontSize-l;
    line-height: 26px;
  }
}
