/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Background {
  background: #f7edf2;
  margin-top: 5px;
}

.Gradient {
  background: linear-gradient(0deg, #ffffff 100px, #f7edf2 100%);
}

.Title {
  align-items: center;
  color: $greyDarkest;
  display: flex;
  font-family: $fontFamily;
  font-size: $fontSize-xxl;
  justify-content: space-between;
  line-height: 36px;
  margin: 16px 0;
  text-align: center;

  span {
    flex: 0 0 auto;
    max-width: 100%;
    padding: 0 6px;
    width: 290px;
  }

  svg {
    height: 100%;
    max-height: 64px;
    max-width: 100%;
    width: 178px;
  }
}

.TitleWrapper {
  align-items: center;
  background: no-repeat center bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 224px;
}

.Subtitle {
  color: $greyDarkest;
  font-size: $fontSize-sm;
  line-height: 22px;
  max-width: 520px;
  text-align: center;
}

.Box {
  background: #ffffff;
  border-radius: 4px;
  min-height: 300px;
  padding: 7px 10px;
}

.Content {
  color: $grey;
  font-size: $fontSize-sm;
  line-height: 22px;
  margin: 0 auto;
  max-width: 880px;
  padding: 25px 0;

  p {
    margin-bottom: 15px;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .TitleWrapper {
    background-size: auto 100%;
  }
}

@media screen and ( $mobile-media ) {

  .Title {
    display: block;

    svg {
      display: none;
    }

    span {
      padding: 0;
    }
  }
}

@media screen and ( $custom-370-media ) {

  .Title {
    font-size: $fontSize-xl;
  }
}
