/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CreatorProgressBar {
  display: flex;
  margin-top: 53px;
}

.CreatorProgressBarItem {
  background: $greyLightest;
  flex-grow: 1;
  height: 2px;
  position: relative;

  &:after {
    background: $greyLightest;
    border: 4px solid $white;
    border-radius: 50%;
    content: '';
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
  }

  &.CreatorProgressBarItemActive {
    background: $purpleDarker;

    &:after {
      background: $purpleDarker;
    }
  }

  &.CreatorProgressBarItemCurrent {

    .CreatorProgressBarItemCount {
      opacity: 1;
    }
  }
}

.CreatorProgressBarItemCount {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  color: $black;
  font-size: $fontSize-s;
  line-height: 20px;
  opacity: 0;
  position: absolute;
  right: -5px;
  text-align: center;
  top: -40px;
  width: 34px;

  &:after {
    border-color: $greyLightest transparent transparent transparent;
    border-style: solid;
    border-width: 5px 3px 0;
    bottom: -6px;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .CreatorProgressBar {
    margin-top: 43px;
  }
}

@media screen and ( $mobile-media ) {

  .CreatorProgressBarItem {
    height: 1px;

    &:after {
      height: 7px;
      width: 7px;
    }
  }

  .CreatorProgressBarItemCount {
    right: -9px;
  }
}
