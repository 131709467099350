/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Box {
  background: $greyLightestPlus;
  border-radius: 10px;
  clear: both;
  margin-bottom: 24px;
  padding: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: $font-weight-l;
  }
}

.BoxBorder {
  border: 1px solid $purpleDarker;
}

.BoxBorderGray {
  border: 1px solid $greyLightest;
}

.BoxTitle {
  color: $greyDarker;
  font-size: 18px;
  font-weight: $font-weight-l;
  line-height: 20px;
  margin-bottom: 36px;
}

.BoxCorner {
  color: $greyDarker;
  float: right;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 12px;
}

.BoxContent {
  clear: both;
  color: $greyDarker;

  p {
    margin-bottom: 22px;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .Box {
    padding: 24px 16px;
  }

  .BoxCorner {
    text-align: right;
    width: 100%;
  }
}
