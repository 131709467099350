/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CreatorNavigation {
  border-top: 1px solid $greyLightest;
  display: flex;
  justify-content: space-between;
  padding-top: 32px;

  :global {

    button {
      width: 160px;
    }
  }
}

.CreatorNavigationCenter {

  a {
    margin: 0 auto;
    max-width: 340px;
  }
}

.CreatorContent {
  margin: 64px 0;
}

.CreatorButton {
  justify-content: flex-end;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .CreatorContent {
    margin: 46px 0;
  }
}

@media screen and ( $mobile-media ) {

  .CreatorContent {
    margin: 24px 0 36px;
    padding: 0 14px;
  }

  .CreatorNavigation {
    border: 0;
    padding-top: 0;
  }
}

@media screen and ( $custom-350-media ) {

  .CreatorNavigation {

    :global {

      button {
        width: 145px;
      }
    }
  }

  .CreatorContent {
    padding: 0;
  }
}
