/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.NotConfirmed p {
  color: $greyDarker;
  font-size: $fontSize-sm;
  margin-bottom: 24px;
}

.Bold {
  font-weight: $font-weight-l;
}

.Box {
  background-color: $greyLightestPlus;
  border: 1px solid $greyLightest;
  min-height: 17px;
}

.EmailBox {
  border-radius: 8px;
  display: flex;
  margin-bottom: 24px;
  max-width: 610px;
  padding: 12px 6px;
  position: relative;
}

.EmailBoxColumn {
  background-color: $white;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  flex: 1 1 auto;
  margin: 0 6px;
  padding: 13px 15px 0;
}

.EmailBoxColumnContent {
  text-align: center;
  width: 63%;

  p {
    color: $black;
    font-size: $fontSize-s;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.BoxRounded {
  border-radius: 8px;
  height: 27px;
  margin-bottom: 17px;
  margin-top: 5px;
  width: 82%;
}

.Inbox {
  color: $black;
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  line-height: 22px;
}

.BoxList {

  li {
    margin-bottom: 16px;

    &:nth-of-type(1) {
      margin-top: 11px;
      width: 93%;
    }

    &:nth-of-type(2) {
      width: 81%;
    }

    &:nth-of-type(3) {
      width: 68%;
    }

    &:nth-of-type(4) {
      width: 89%;
    }

    &:nth-of-type(5) {
      width: 77%;
    }

    &:nth-of-type(6) {
      width: 96%;
    }
  }
}

.Logo {
  margin: 0 auto 18px;
  max-width: 100%;
  width: 226px;
}

.Button {
  margin: 0 auto 20px;
  max-width: 227px;

  button {
    cursor: default;
  }
}

.Pointer {
  display: flex;
  position: absolute;
  right: -182px;
  top: 76px;

  img {
    height: auto;
    margin-top: 25px;
    width: 132px;
  }

  p {
    color: $grey;
    font-family: $fontFamilyTertiary;
    font-size: $fontSize-xl;
    font-weight: $font-weight-m;
    line-height: 26px;
    padding-left: 4px;
    width: 162px;
  }
}

.ButtonPointerTop {
  display: none;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .EmailBox {
    max-width: 75%;
  }

  .Pointer {
    right: -38%;
  }
}

@media screen and ( $mobile-media ) {

  .EmailBox {
    margin-bottom: 74px;
    max-width: 100%;
    padding: 8px 4px;

    p {
      margin-bottom: 14px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .EmailBoxColumn {
    margin: 0 4px;
    padding: 12px 10px 6px;
  }

  .BoxRounded {
    height: 21px;
    margin-bottom: 13px;
    margin-top: 0;
    width: 73%;
  }

  .Inbox {
    font-size: $fontSize-xs;
    line-height: 12px;
  }

  .BoxList {

    li {
      margin-bottom: 10px;
    }
  }

  .Button {

    button {
      line-height: 20px;
    }
  }

  .Logo {
    margin-bottom: 0;
    padding: 0 10px;
  }

  .Gradient {
    position: relative;

    &:after {
      background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .Pointer {
    right: 7%;
    top: 70%;

    img {
      margin: 0;
      width: 100px;
    }

    p {
      font-size: 19px;
      line-height: 21px;
      margin-top: 120px;
      padding: 0;
      width: 110px;
    }
  }

  .ButtonPointer {
    display: none;
  }

  .ButtonPointerTop {
    display: block;
  }
}
