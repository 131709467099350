/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.AlignCenter {
  text-align: center;
}

.Divider {
  border-top: 1px solid $greyLightest;
  margin: 25px 0;
}

.ButtonWrapper {
  margin: 0 auto;
  max-width: 160px;
}

.ButtonCenter {
  margin: 0 auto;
  max-width: 250px;
  min-width: 160px;
}
